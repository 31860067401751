$modalSelector: ".#{$prefix}modal";
$modalScreenDropShadow: rgba(0, 0, 0, 0.7) !default;
$modal-z-index: 100 !default;

#{$modalSelector} {
  position: fixed;
  top: 0;
  inset-inline-start: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: $modal-z-index;
  background: $modalScreenDropShadow;
  display: none;
  align-items: center;
  justify-content: center;

  &:not(.active) {
    display: none;
  }

  &.active:last-of-type {
    // no multiple modals at the same time. only the last one shows
    display: flex;
  }

  // TODO: wide variation

  // todo: narrow variation

  // todo: auto scrollable
  &__inner {
    position: relative;
    flex: auto;
    width: auto;
    min-height: 100px;
    max-height: 100%;
    background: var(--c-bg);
    border: 1px solid var(--c-gray-2);
    box-shadow: var(--box-shadow-5);
    padding: 0;
    margin: var(--margin-2xl);
    min-width: 500px;
    border-radius: var(--border-radius-s);
    max-width: $screen-m-max;

    @include whenSmallerThan(m) {
      margin: var(--margin-xl) var(--margin-l);
      min-width: 100px;
      max-width: calc(100vw - 2 * var(--margin-m));
      overflow: auto; // failsafe, can always scroll the entire thing
    }

    @include whenSmallerThan(400px) {
      margin: var(--margin-m) var(--margin-xs);
      max-width: 100%;
    }

    &--full {
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      margin: 0;
      overflow: auto;

      @include whenSmallerThan(m) {
        margin: 0;
        overflow: auto; // failsafe, can always scroll the entire thing
      }

      @include whenSmallerThan(400px) {
        margin: 0;
      }
    }

    &--auto {
      width: auto;
      flex: 0 0 auto;
      max-width: 95vw;
      max-height: 95vh;
      overflow: auto;
      padding: 0;

      .modal__body {
        max-height: 95vh;
      }
      @include whenSmallerThan(l) {
        //   width: 90vw;
        //  flex: 0 0 90vw;
      }

      @include whenSmallerThan(m) {
        margin: 0;
        overflow: auto; // failsafe, can always scroll the entire thing
      }

      @include whenSmallerThan(400px) {
        margin: 0;
      }
    }

    &--2xlarge,
    &--extra--extra-large,
    &--2xl {
      width: 95vw;
      flex: 0 0 95vw;
      max-width: 95vw;
      min-height: 95vh;
      max-height: 95vh;
      overflow: auto;

      .modal__body {
        max-height: 95vh;
      }

      @include whenSmallerThan(l) {
        width: 95vw;
        flex: 0 0 95vw;
      }

      @include whenSmallerThan(m) {
        margin: 0;
        overflow: auto; // failsafe, can always scroll the entire thing
      }

      @include whenSmallerThan(400px) {
        margin: 0;
      }
    }

    &.modal__inner--xlarge,
    &.modal__inner--extra-large,
    &.modal__inner--xl {
      width: 80vw;
      flex: 0 0 80vw;
      max-width: 1600px;
      min-height: 90vh;
      max-height: 90vh;
      overflow: auto;

      .modal__body {
        max-height: calc(90vh);
        max-width: 100%;
      }

      @include whenSmallerThan(l) {
        width: 90vw;
        flex: 0 0 90vw;
      }

      @include whenSmallerThan(m) {
        margin: 0;
        overflow: auto; // failsafe, can always scroll the entire thing
      }

      @include whenSmallerThan(400px) {
        margin: 0;
      }
    }

    &--large,
    &--l {
      width: 65vw;
      flex: 0 0 65vw;
      max-width: 1100px;
      min-height: 75vh;
      max-height: 95vh;
      overflow: auto;

      @include whenSmallerThan(l) {
        width: 90vw;
        flex: 0 0 90vw;
      }

      @include whenSmallerThan(m) {
        margin: 0;
        overflow: auto; // failsafe, can always scroll the entire thing
      }

      @include whenSmallerThan(400px) {
        margin: 0;
      }
    }

    &--small,
    &--s {
      width: 350px;
      flex: 0 0 350px;
      max-width: 500px;
      min-height: 25vh;
      max-height: 45vh;
      overflow: auto;

      @include whenSmallerThan(l) {
        width: 90vw;
        flex: 0 0 90vw;
        max-height: 95vh;
      }

      @include whenSmallerThan(m) {
        margin: 0;
        overflow: auto; // failsafe, can always scroll the entire thing
      }

      @include whenSmallerThan(400px) {
        margin: 0;
      }
    }
  }

  &__icon-close {
    position: absolute;
    top: var(--margin-m);
    inset-inline-end: var(--margin-m);
    z-index: 2;
    cursor: pointer;
    @include transition(opacity);

    &:hover {
      opacity: 0.8;
    }

    @include whenSmallerThan(m) {
      top: var(--margin-s);
      inset-inline-end: var(--margin-s);
    }
  }

  &__header {
    position: relative;
    padding: var(--margin-l) var(--margin-xl) var(--margin-l) var(--margin-xl);
    border-bottom: 1px solid var(--c-gray-2);

    @include whenSmallerThan(s) {
      padding: calc(var(--margin-l) * 0.8) var(--margin-l);
    }

    #{$modalSelector}__title {
      padding-inline-end: var(--margin-l);
      @include whenSmallerThan(m) {
        padding-inline-end: var(--margin-l);
      }

      &--center {
        text-align: center;
        padding-inline-start: var(--margin-l);
      }
    }
  }

  #{$modalSelector}__title {
    font-size: var(--font-3xl);
    margin-bottom: 0;

    @include whenSmallerThan(s) {
      font-size: var(--font-2xl);
    }
  }

  &__body {
    padding: var(--margin-2xl) var(--margin-xl) var(--margin-xl)
      var(--margin-xl);
    overflow: auto;
    max-height: calc(80vh - var(--margin-xl));

    @include whenSmallerThan(s) {
      padding: var(--margin-l);
    }
  }

  &--tight {
    #{$modalSelector}__body {
      padding: 0;
    }
  }

  // modal with header only
  &--has-header #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(80vh - 80px);
  }

  // has header + full
  &--has-header #{$modalSelector}__inner--full #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(100vh - 82px);
  }

  // has header + 2xlarge
  &--has-header #{$modalSelector}__inner--2xlarge #{$modalSelector}__body,
  &--has-header #{$modalSelector}__inner--2xl #{$modalSelector}__body,
  &--has-header
    #{$modalSelector}__inner--extra-extra-large
    #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(95vh - 80px);
  }

  // has header +xlarge
  &--has-header #{$modalSelector}__inner--xlarge #{$modalSelector}__body,
  &--has-header #{$modalSelector}__inner--xl #{$modalSelector}__body,
  &--has-header #{$modalSelector}__inner--extra-large #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(90vh - 80px);
  }

  // modal with footer only
  &--has-footer #{$modalSelector}__body {
    max-height: calc(80vh - 80px);
  }

  // has footer + full
  &--has-footer #{$modalSelector}__inner--full #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(100vh - 82px);
  }

  // has footer + 2xlarge


  // has footer + 2xlarge
  &--has-footer #{$modalSelector}__inner--2xlarge #{$modalSelector}__body,
  &--has-footer #{$modalSelector}__inner--2xl #{$modalSelector}__body,
  &--has-footer
    #{$modalSelector}__inner--extra-extra-large
    #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(95vh - 80px);
  }

  // has footer +xlarge
  &--has-footer #{$modalSelector}__inner--xlarge #{$modalSelector}__body,
  &--has-footer #{$modalSelector}__inner--xl #{$modalSelector}__body,
  &--has-footer #{$modalSelector}__inner--extra-large #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(90vh - 80px);
  }
  // modal with header and footer
  &--has-header#{$modalSelector}--has-footer #{$modalSelector}__body {
    max-height: calc(80vh - 160px);
  }

  // has footer + header + full
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--full
    #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    min-height: calc(100vh - 166px);
    max-height: calc(100vh - 166px);
  }

  // has footer + header + 2xlarge
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--2xlarge
    #{$modalSelector}__body,
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--2xl
    #{$modalSelector}__body,
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--extra-extra-large
    #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(95vh - 160px);
  }

  // has footer + header + xlarge
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--xlarge
    #{$modalSelector}__body,
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--xl
    #{$modalSelector}__body,
  &--has-header#{$modalSelector}--has-footer
    #{$modalSelector}__inner--extra-large
    #{$modalSelector}__body {
    padding-top: var(--margin-xl);
    max-height: calc(90vh - 160px);
  }

  &__footer {
    display: flex;
    gap: var(--margin-l);
    border-top: 1px solid var(--c-gray-2);
    padding: var(--margin-l) var(--margin-xl) var(--margin-l) var(--margin-xl);
    justify-content: flex-end;
    align-items: center;

    @include whenSmallerThan(s) {
      padding: calc(var(--margin-l) * 0.8) var(--margin-l);
    }

    a {
      text-decoration: underline;
    }

    .button-group {
      align-items: center;
      margin: 0;
    }
  }

  @if (mixin-exists(hook-modal)) {
    @include hook-modal();
  }
}
