$togglePrefix : '.#{$prefix}toggle';


#{$togglePrefix} {
    --toggle-size : calc(var(--margin-l) * 0.9);
    --toggle-width : calc(var(--toggle-size) * 3);
    --knob-width: calc(var(--toggle-size) + 8px);
    $toggleSize : var(--toggle-size);

    &__controls {
        display: flex;
        margin-inline-start: 0;
        margin-inline-end: auto;


        &.active {
            .toggle__controls-line {
                background: var(--c-bg-4);
            }

            .toggle__controls-knob {
                transform: translateX(calc( var(--toggle-width) - var(--knob-width) + 6px));
            }
        }

        [dir="rtl"] {
            #{$togglePrefix}__controls-knob {
                left: auto;
                right: 2px;
            }

            &.active {
                #{$togglePrefix}__controls-knob {
                    transform: translateX(calc(-1 * var(--toggle-width) - var(--knob-width) + 6px));
                }
            }
        }
    }

    &__controls-line {
        position: relative;
        border-radius: calc(var(--toggle-size) / 1.5);
        border: 1px solid var(--c-gray-2);
        background: var(--c-bg-4);
        height: calc(var(--toggle-size) + 4px);
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1) inset;
        width: var(--toggle-width);
        cursor: pointer;
    }

    &__controls-knob {
        position: absolute;
        background: var(--c-bg-4);
        border-radius: calc(var(--toggle-size) / 1.5);
        height: calc(var(--toggle-size) + 8px);
        width: var(--knob-width);
        top: -3px;
        left: -5px;
        transition: background 350ms,
        transform 350ms cubic-bezier(1, 0.09, 0.65, 0.96);
        box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1);
    }

    &__label {
        cursor: pointer;
        margin-inline-end: calc(var(--margin-m) * 1.5);
    }

    &__label--end {
        cursor: pointer;
        margin-inline-start: calc(var(--margin-m) * 1.5);
        margin-inline-end: 0;
    }
}
