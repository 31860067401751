:root {
    --drawer-offset: 0px;
    --drawer-width: 300px;
}

.#{$prefix}drawer-shader {
    z-index    : var(--z-top-2);
    background-color: rgba(0,0,0,0.5);
    pointer-events: none;
    opacity: 0;
    transition: all 300ms ease 5ms;

    &.active {
        opacity: 1;
        pointer-events: all;
    }
}

.#{$prefix}drawer {
    position   : fixed;
    z-index    : var(--z-top-3);
    top        : var(--drawer-offset);
    inset-inline-start       : 0;
    width      : var(--drawer-width);
    background : var(--c-bg);
    box-shadow : var(--box-shadow-5);
    padding: var(--margin-l);
    max-width: 100vw;
    max-width: 100lvw;

    max-height: 100vh;
    max-height: 100lvh;
    height     : calc(100vh - var(--drawer-offset));
    //noinspection CssInvalidPropertyValue
    height     :  calc(100lvh - var(--drawer-offset));


    // transition
    transform: translateX(-100%);
    opacity: 0;
    transition: all 500ms ease 10ms;

    [dir=rtl] & {
        transform: translateX(100%);
    }


    &.active {
        transform: none;
        opacity: 1;
    }

    &--inline-end {
        inset-inline-start: auto;
        inset-inline-end: 0;
        transform: translateX(100%);

        [dir=rtl] & {
            transform: translateX(-100%);
        }
    }

    &--bottom {
        position   : fixed;
        overflow: auto;
        top: auto;
        margin-bottom: calc(-1 * var(--drawer-offset));
        bottom        : var(--drawer-offset);
        inset-inline-start       : 0;
        inset-inline-end       : 0;
        height: auto;
        min-height      : calc(var(--drawer-width));
        max-height: calc(100vh - var(--drawer-offset));
        max-height: calc(100lvh - var(--drawer-offset));

        width     : calc(100vw );
        //noinspection CssInvalidPropertyValue
        width     :  calc(100lvw);
        transform: translateY(100%);

        [dir=rtl] & {
            transform: translateY(100%);
        }

        &.active,  [dir=rtl] &.active  {
            margin-top: 0;
            transform: none;
        }
    }

    &--top {
        position   : fixed;
        overflow: auto;
        margin-top: calc(-1 * var(--drawer-offset));
        top: var(--drawer-offset);
        bottom        : auto;
        inset-inline-start       : 0;
        inset-inline-end       : 0;
        height: auto;
        min-height      : calc(var(--drawer-width));
        max-height: calc(100vh - var(--drawer-offset));
        max-height: calc(100lvh - var(--drawer-offset));

        width     : calc(100vw );
        //noinspection CssInvalidPropertyValue
        width     :  calc(100lvw);
        transform: translateY(-100%);

        &.active {
            margin-top: 0;
        }

    }

    .drawer-content {
        padding: var(--margin-s) var(--margin-m) var(--margin-m) var(--margin-m);
    }
}
