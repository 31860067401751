
$button-border-thickness : 2px !default;
$button-selector : '.#{$prefix}button';
$button-font-size                : $font-m !default;
$button-font-size-small          : $font-xs !default;
$button-font-size-large          : $font-xl !default;

$button-height                   : 2.5em !default;
$button-padding-horizontal       : 1.7em !default;
$button-padding-horizontal-large : 1.9em !default;


$button-padding-horizontal-slim       : 0.3em !default;

$button-height-small             : 2.2em !default;
$button-padding-horizontal-small : 1.5em !default;

$button-height-large             : 2.4em !default;


$button-centering-fix-ratio      : 20 !default;
// size modifier - regular, large

// border modifier - regular, round, sharp

// color modifier
@mixin _button-color($bg, $color, $hover-bg, $hover-color, $hover-border-inverse: false) {
    background-color : #{$bg};
    color            : #{$color};
    border-color     : #{$bg};

    &:hover {
        background-color : #{$hover-bg};
        color            : #{$hover-color};

        @if ($hover-border-inverse) {
            border-color : #{$bg};
        } @else {
            border-color : #{$hover-bg};
        }
    }

    &[disabled], &.disabled, &--disabled, &--loading {
        &:hover {
            background-color : #{$bg};
            color            : #{$color};
            border-color     : #{$bg};
        }
    }

    &.#{$prefix}button--loading:hover {
        background-color : #{$bg};
        color            : #{$color};
        border-color     : #{$bg};
    }
}

.#{$prefix}button-group {
    display       : flex;
    gap           : 0;
    flex-wrap     : wrap;
    align-items   : flex-end;
  //  margin-top    : $margin-m;
  //  margin-bottom : $margin-m;


    > #{$button-selector}:first-of-type,
    > *:first-child #{$button-selector}  {
        margin-inline-start: 0;
        border-radius: var(--border-radius-sharp) 0 0 var(--border-radius-sharp);
    }

    > #{$button-selector}:last-of-type,
    > *:last-child #{$button-selector} {
        border-radius: 0 var(--border-radius-sharp) var(--border-radius-sharp) 0;
    }

    // only child - no border modifications
    > #{$button-selector}:only-child,
    > *:only-child #{$button-selector}  {
        border-radius:   var(--border-radius-sharp);
    }

    [dir=rtl] & > *:first-of-type #{$button-selector},
    [dir=rtl] & > #{$button-selector}:first-of-type { // inverted style for rtl
        border-radius:  0 var(--border-radius-sharp) var(--border-radius-sharp) 0;
    }

    [dir=rtl] & > *:last-of-type #{$button-selector},
    [dir=rtl] & > #{$button-selector}:last-of-type { // inverted style for rtl
        border-radius: var(--border-radius-sharp) 0 0 var(--border-radius-sharp);
    }


     #{$button-selector} {
        border-radius: 0;
        margin-inline-start: calc((#{$button-border-thickness} / 2) * -1);


        // theme variations
        &--alt-inverse {
            @include _button-color( $color-bg, $color-alt, $color-alt, $color-bg, false);
            border-color:  $color-alt;
        }

        &--bg {
            @include _button-color($color-bg, $color-inverse, $color-inverse, $color-bg, false);
            border-color : $color-inverse;
        }
    }

    &--vertical > #{$button-selector} ,
    [dir=rtl] &--vertical > #{$button-selector},{
        border-radius: 0;
        margin-top: calc((#{$button-border-thickness} / 2) * -1);

        &:first-of-type {
            margin-top: 0;
            border-radius: var(--border-radius-sharp)  var(--border-radius-sharp) 0 0;
        }

        &:last-of-type {
            border-radius:  0 0 var(--border-radius-sharp) var(--border-radius-sharp) ;
        }



    }

    &--center, &--justify-center {
        justify-content : center;
    }

    &--justify-end {
        justify-content : flex-end;
    }

    &--align-end {
        align-items : flex-end;
    }

    &--align-center {
        align-items : center;
    }

    &--align-top {
        align-items : flex-start;
    }

    // theme variations

}

#{$button-selector} {
    display              : flex;
    justify-content      : center;
    align-items          : center;

    // centerig fix
    padding-top          : calc(#{$button-height} / #{$button-centering-fix-ratio});

    padding-inline-end   : $button-padding-horizontal;
    padding-inline-start : $button-padding-horizontal;
    height               : $button-height;
    min-height           : $button-height; // for flex column
    border               : #{$button-border-thickness} solid transparent;
    border-radius        : $border-radius-sharp;
    font-size            : $button-font-size;
    font-weight          : normal;
    cursor               : pointer;
    text-decoration : none!important;
    text-decoration-color: transparent!important;

    // default coloring rules
    @include _button-color($color-lead, $color-bg, $color-bg, $color-lead, true);
    @include prefix(transition, 'color 175ms ease, background 175ms ease');


    a:hover & {
        text-decoration: none;
    }

    &[disabled], &.disabled, &--disabled {
        opacity: 0.7;
        cursor: not-allowed;
    }

    // link button fix
    &:hover {
        text-decoration : none!important;
        text-decoration-color: transparent!important;

        span, span:hover {
            text-decoration : none!important;
            text-decoration-color: transparent!important;
        }
    }

    // fix when inside link
    a:hover &,  {
        text-decoration : none!important;
        text-decoration-color: transparent!important;

        span, span:hover {
            text-decoration : none!important;
            text-decoration-color: transparent!important;
        }
    }

    &--slim {
        padding-inline-start: $button-padding-horizontal-slim!important;
        padding-inline-end: $button-padding-horizontal-slim!important;
    }
    // size modifiers
    &--small, &--s {
        font-size            : $button-font-size-small;
        padding-inline-end   : $button-padding-horizontal-small;
        padding-inline-start : $button-padding-horizontal-small;
        height               : $button-height-small;
        min-height           : $button-height-small; // for flex column
    }

    &--large, &--l {
        padding-inline-end   : $button-padding-horizontal-large;
        padding-inline-start : $button-padding-horizontal-large;
        height               : $button-height-large;
        min-height           : $button-height-large; // for flex column
        font-size            : $button-font-size-large;

        & .#{$prefix}button__icon {
            height:  $font-3xl;
            width:   $font-3xl;
            background-size: contain;
            background-position: center center;
            margin-inline-end: $margin-s;

            &:only-child {
                margin-inline-end: 0;
            }
        }

        & .#{$prefix}button__icon-end {
            height:  $font-3xl;
            width:   $font-3xl;
            background-size: contain;
            background-position: center center;
            margin-inline-start: $margin-s;

            &:only-child {
                margin-inline-start: 0;
            }
        }

    }

    // border modifiers
    &--sharp {
        border-radius : $border-radius-sharp;
    }

    &--round {
        border-radius : $border-radius-round;
    }

    // color modifiers
    &--lead {
        @include _button-color($color-lead, $color-bg, $color-bg, $color-lead-4, true);
    }

    &--lead-inverse {
        @include _button-color($color-bg, $color-lead,  $color-lead, $color-bg, false);
        border-color:  $color-lead;
    }

    &--lead-2 {
        @include _button-color($color-lead-2, $color-bg, $color-bg, $color-lead-2, true);
    }

    &--alt {
        @include _button-color($color-alt, $color-bg, $color-bg, $color-alt, true);
    }

    &--alt-inverse {
        @include _button-color( $color-bg, $color-alt, $color-alt, $color-bg, true);
        border-color:  $color-alt;
    }

    &--alt-2 {
        @include _button-color($color-alt-2, $color-bg, $color-bg, $color-alt-2, true);
    }

    &--inverse {
        @include _button-color($color-inverse, $color-bg, $color-bg, $color-inverse, true);
    }

    &--bg {
        @include _button-color($color-bg, $color-inverse, $color-inverse, $color-bg, true);
        border-color : $color-inverse;
    }

    &--gray-2 {
        @include _button-color($color-gray-2, $color-inverse, $color-inverse, $color-gray-2, true);
    }

    &--gray {
        @include _button-color($color-gray, $color-bg, $color-bg, $color-gray, true);
    }

    &--gray-4 {
        @include _button-color($color-gray-4, $color-bg, $color-bg, $color-gray-4, true);
    }

    &--gray-5 {
        @include _button-color($color-gray-5, $color-bg, $color-bg, $color-gray-5, true);
    }

    &--comp-lead {
        @include _button-color($color-comp-lead, $color-bg, $color-bg, $color-comp-lead, true);
    }

    &--comp-lead-2 {
        @include _button-color($color-comp-lead-2, $color-bg, $color-comp-lead, $color-bg, false);
    }

    &--comp-alt {
        @include _button-color($color-comp-alt, $color-inverse, $color-inverse, $color-bg, false);
    }

    &--comp-alt-2 {
        @include _button-color($color-comp-alt-2, $color-inverse, $color-inverse, $color-bg, false);
    }

    &--success {
        @include _button-color($color-success, $color-bg, $color-bg, $color-success,  true);
    }

    &--bg-transparent {
        @include _button-color($color-bg, $color-inverse, transparent, $color-bg, true);
    }

    &--warning {
        @include _button-color($color-warning, $color-bg, $color-bg, $color-warning,  true);
    }

    &--danger, &--error {
        @include _button-color($color-danger, $color-bg, $color-bg, $color-danger,  true);
    }

    &__icon {
        height: $margin-xl;
        width: $margin-xl;
        background-size: contain;
        margin-inline-end: $margin-s;
        background-repeat: no-repeat;

        &:only-child {
            margin-inline-end: 0;
        }
        // centerig fix
      //  @include prefix(transform, translateY(-10%));

        &.#{$prefix}button--large {
            height: $margin-2xl;
            width: $margin-2xl;


        }
    }

    &__icon-end {
        height: $margin-xl;
        width: $margin-xl;
        background-size: contain;
        margin-inline-start: $margin-s;

        &:only-child {
            margin-inline-start: 0;
        }
    }

    &--icons-absolute {
        position: relative;
        .#{$prefix}button__icon-end {
            position: absolute;
            margin: 0;
            top: 50%;
            inset-inline-end: 1em;
            transform: translateY(-50%);
        }

        .#{$prefix}button__icon-start, .#{$prefix}button__icon {
            position: absolute;
            margin: 0;
            top: 50%;
            inset-inline-start: 1em;
            transform: translateY(-50%);
        }
    }
    @if (mixin-exists(hook-button)) {
        @include hook-button();
    }
}

// link container text decoration fix
a:has(#{$button-selector}):hover {
    text-decoration: none!important;
}

